import { getColumnSize } from "modules/common/services/table/size-storage";
import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { RowNumberColumn } from "modules/common/services/table/helpers";
import { fields, EmployerSnapshotType, EmployerType } from "../../models/employer";
import Schema from "../details/validation";
import { getFieldLabel } from "modules/common/services/form/fields";
import React, { useCallback } from "react";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { texts } from "modules/common/texts";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { TelegramLink } from "modules/common/components/links/TelegramLink";
import { EmailLink } from "modules/common/components/links/EmailLink";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import styles from "./EmployerList.module.scss";

const schema = Schema();

export const columns = (tableName: string) => {
  const columnSize = getColumnSize(tableName);

  const result: ColDef[] = [
    RowNumberColumn(false, ""),
    {
      headerName: getFieldLabel(fields.name, schema, null),
      field: fields.name,
      width: columnSize(fields.name, 250),
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Задания",
      field: fields.workQuestions,
      width: columnSize(fields.workQuestions, 90),
      sortable: true,
      resizable: true,
      cellRendererFramework: (params: ICellRendererParams) => {
        const { data } = params;
        const getContext = () => params.context as TableContext;

        return data && (data.workQuestions > 0 || data.expQuestions > 0) ? (
          <div className={styles.questions}>
            {data.workQuestions > 0 && (
              <span
                onClick={(e: any) => {
                  e.stopPropagation();
                  getContext().goToWorkQuestions(data);
                }}
                className={styles.workData}
              >
                {data.workQuestions}
              </span>
            )}
            {data.expQuestions > 0 && (
              <span
                onClick={(e: any) => {
                  e.stopPropagation();
                  getContext().goToExpQuestions(data);
                }}
                className={styles.expData}
              >
                {data.expQuestions}
              </span>
            )}
          </div>
        ) : null;
      },
    },
    {
      headerName: "Отдел",
      field: "department",
      width: columnSize("department", 200),
      valueGetter: ({ data }) => data.department.name,
      sortable: true,
      resizable: true,
    },
    {
      headerName: getFieldLabel(fields.positionId, schema, null),
      field: fields.positionId,
      flex: 1,
      valueGetter: ({ data }) => data.employerPosition.name,
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Увол.",
      field: fields.fireDate,
      width: columnSize(fields.fireDate, 120),
      headerClass: "centered",
      cellClass: "centered",
      sortable: true,
      resizable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const dateA = (nodeA.data as EmployerSnapshotType).fireDateSortable;
        const dateB = (nodeB.data as EmployerSnapshotType).fireDateSortable;
        return dateA.localeCompare(dateB);
      },
    },
    {
      headerName: "",
      field: fields.canBeReturn,
      width: columnSize(fields.canBeReturn, 60),
      sortable: true,
      resizable: true,
      cellRendererFramework: ({ value }: ICellRendererParams) => {
        return value ? <GeneralIcon type="general-boomerang" size={20} /> : "";
      },
    },
    {
      headerName: "Совм",
      field: fields.poohBah,
      width: columnSize(fields.poohBah, 80),
      sortable: true,
      resizable: true,
      valueFormatter: ({ value }) => {
        return value ? "Да" : "";
      },
    },

    {
      headerName: getFieldLabel(fields.phone, schema, null),
      field: fields.phone,
      width: columnSize(fields.phone, 150),
      sortable: true,
      resizable: true,
      valueFormatter: ({ value }) => formatPhoneNumber(value),
    },
    {
      headerName: "Телеграм",
      field: fields.tgUser,
      width: columnSize(fields.tgUser, 140),
      sortable: true,
      resizable: true,
      headerClass: "centered",
      cellClass: "centered",
      cellRendererFramework: ({ value, data }: ICellRendererParams) => {
        const user = data as EmployerSnapshotType;
        if (value) {
          return <TelegramLink value={value} />;
        }

        if (user.tgRegistered) {
          return <GeneralIcon type="general-check" size={12} />;
        }

        return null;
      },
    },
    {
      headerName: getFieldLabel(fields.email, schema, null),
      field: fields.email,
      width: columnSize(fields.email, 230),
      sortable: true,
      resizable: true,
      cellRendererFramework: ({ value }: ICellRendererParams) => {
        return value === "" ? null : <EmailLink value={value} />;
      },
    },
  ];

  return result;
};

export const columnsRemoved = (tableName: string, canChange: boolean) => {
  const columnSize = getColumnSize(tableName);

  const result: ColDef[] = [
    RowNumberColumn(false, ""),
    {
      headerName: getFieldLabel(fields.name, schema, null),
      field: fields.name,
      width: columnSize(fields.name, 250),
      sortable: true,
      resizable: true,
    },
    {
      headerName: getFieldLabel(fields.login, schema, null),
      field: fields.login,
      width: columnSize(fields.login, 130),
      sortable: true,
      resizable: true,
    },
    {
      headerName: getFieldLabel("department", schema, null),
      field: "department",
      width: columnSize("department", 200),
      valueGetter: ({ data }) => data.department.name,
      sortable: true,
      resizable: true,
    },
    {
      headerName: getFieldLabel(fields.positionId, schema, null),
      field: fields.positionId,
      width: columnSize(fields.positionId, 300),
      valueGetter: ({ data }) => data.employerPosition.name,
      sortable: true,
      resizable: true,
    },
  ];

  if (canChange) {
    result.push({
      headerName: "",
      field: fields.isRemoved,
      width: columnSize(fields.isRemoved, 100),
      cellRendererFramework: RestoreButton,
      cellClass: "centered",
      sortable: false,
      resizable: false,
    });
  }

  return result;
};

function RestoreButton(params: ICellRendererParams) {
  const onClick = useCallback(() => {
    const actions = params.context as RestoreActions;
    actions.confirmRestore && actions.confirmRestore(params.data);
  }, [params]);

  return <PlanrButton type="neutral" icon="general-undo" onClick={onClick} size="small" title={texts.restore} />;
}

export interface RestoreActions {
  confirmRestore?: (user: EmployerSnapshotType) => void;
}

export interface TableContext {
  goToWorkQuestions: (emp: EmployerType) => void;
  goToExpQuestions: (emp: EmployerType) => void;
}
