import { SortChangedEvent } from "@ag-grid-community/core";
import { saveAs } from "file-saver";
import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import { DATE_TIME_FORMAT, DEFAULT_SORTING_ASCENDING_VALUE, EMPTY_OBJECT_ID } from "modules/common/constants";
import { isNewlyCreated } from "modules/common/models/entity";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Queryable } from "modules/common/models/queryable";
import { TablePager } from "modules/common/models/table-pager";
import { TableSorter } from "modules/common/models/table-sorter";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob } from "modules/common/services/files";
import { formatDate } from "modules/common/services/formatting/date";
import { capitalize, trimStart } from "modules/common/services/strings";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { texts } from "modules/common/texts";
import {
  OrderStatusDictionary,
  initialState as orderStatusDictionary,
} from "modules/dictionaries/order-statuses/models/order-status-dictionary";
import { OrderDictionary, initialState as emptyOrders } from "modules/orders-manage/models/order-dictionary";
import {
  OrderQuestion,
  OrderQuestionSnapshotType,
  OrderQuestionType,
  SaveQuestion,
  initialState as emptyQuestion,
  questionFields,
} from "modules/orders-manage/models/order-question";
import { RenderCache } from "modules/orders-manage/models/render-cache";
import {
  EmployerDictionary,
  initialState as emptyEmployee,
} from "modules/spending/employee/models/employee-dictionary";
import { parse } from "query-string";

const DEFAULT_COLUMN = "Date";

export const OrderQuestionStore = types
  .compose(
    Notificator,
    Transport,
    TablePager,
    Queryable,
    types.model({
      questions: types.array(OrderQuestion),
      employee: EmployerDictionary,
      orders: OrderDictionary,
      sorter: TableSorter,
      renderer: RenderCache,
      // filters part
      filtersType: types.string,
      filtersEmployer: types.string,
      filtersImplementer: types.string,
      filtersStatus: types.string,
      filtersOrderId: types.string,
      filtersById: types.string,
      filtersByType: types.string,
      filtersByEmpId: types.string,
      selectedStatuses: types.array(types.string),
      statuses: OrderStatusDictionary,
    })
  )
  .views((self) => ({
    get queryParams() {
      const filters: TStringMap<any> = {
        page: self.page,
        column: capitalize(self.sorter.column),
        asc: self.sorter.asc,
        itemsPerPage: self.pageSize,
      };

      if (self.filtersOrderId) {
        filters.orderId = self.filtersOrderId;
      }

      if (self.filtersById) {
        filters.questionId = self.filtersById;
      }

      if (self.filtersByType) {
        filters.typeText = self.filtersByType;
      }

      if (self.filtersByEmpId) {
        filters.employerId = self.filtersByEmpId;
      }

      if (self.filtersType) {
        filters.type = self.filtersType;
      }

      if (self.filtersStatus) {
        filters.status = self.filtersStatus;
      }

      if (self.pureQuery) {
        filters.complexSearch = self.pureQuery;
      }

      return filters;
    },

    // get data() {
    //   return self.questions.map((m) => {
    //     return toJsonHard(m);
    //   });
    // },
    get data() {
      const predicate = (o: OrderQuestionType) => {
        let result = true;

        if (self.selectedStatuses.length > 0) {
          result = result && self.selectedStatuses.includes(o.orderStatusId);
        }

        if (self.filtersOrderId) {
          result = result && self.filtersOrderId === o.orderId;
        }

        if (self.filtersEmployer) {
          result = result && self.filtersEmployer === o.employer?.id;
        }

        if (self.filtersImplementer) {
          result = result && self.filtersImplementer === o.implementer?.id;
        }

        if (self.filtersType) {
          const [day, month, year] = o.stopDate.split(".");

          // Создаем объект Date (месяцы в JavaScript начинаются с 0, поэтому month - 1)
          const stopDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

          // Получаем текущую дату
          const currentDate = new Date();

          if (self.filtersType === "Исполнено") {
            result = result && o.completed;
          }
          if (self.filtersType === "Неисполнено") {
            result = result && !o.completed && stopDate >= currentDate;
          }
          if (self.filtersType === "Просрочено") {
            result = result && !o.completed && stopDate < currentDate;
          }
        }

        if (self.pureQuery) {
          result =
            result &&
            `${o.number} ${o.answer} ${o.description} ${o.employer?.name} ${o.author?.name} ${o.implementer?.name} ${o.day} ${o.stopDay} ${o.order?.inventoryNumber} ${o.order?.name}`
              .toLowerCase()
              .indexOf(self.pureQuery) >= 0;
        }

        if (result) {
        }

        return result;
      };
      let res = self.questions.filter(predicate).map((question) => {
        return {
          ...getSnapshot(question),
        };
      });
      return res;
    },
  }))
  .actions((self) => {
    function loadData() {
      return self.transport.post<OrderQuestionSnapshotType[]>(apiUrls.orders.orderQuestion.list, self.queryParams);
    }

    return {
      load: flow(function* (sliding = false) {
        try {
          if (!self.filtersOrderId) {
            self.orders.isEmpty && self.orders.load();
          }
          self.employee.isEmpty && self.employee.load();

          self.statuses.isEmpty && self.statuses.load();

          if (self.filtersOrderId === EMPTY_OBJECT_ID) {
            applySnapshot(self.questions, []);
            self.totalCount = 0;
          } else {
            // let confirms: PagedList<OrderQuestionSnapshotType> = yield loadData();
            let confirms: OrderQuestionSnapshotType[] = yield loadData();

            if (sliding && confirms.length === 0 && self.page > 1) {
              self.page = self.page - 1;
              confirms = yield loadData();
            }

            applySnapshot(self.questions, confirms);
            self.totalCount = 1;
          }
          return true;
        } catch (e) {
          self.notify.error(e);
          return false;
        }
      }),

      uploadFile: flow(function* (file: File) {
        try {
          const model = new FormData();

          model.append("file", file);
          model.append("accept", "*");

          const result: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);
          const { id, mimeType, previewMimeType } = result;

          const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
          return fileBase;
        } catch (er) {
          self.notify.error(er);
          return null;
        }
      }),
    };
  })
  .actions((self) => ({
    resorted(e: SortChangedEvent) {
      self.sorter.resorted(e, false);
      self.page = 1;
      self.load();
    },

    setPage(page: number) {
      self.page = page;
      return self.load();
    },

    save: flow(function* (message: SaveQuestion) {
      try {
        const body: TStringMap<any> = { ...message };
        // debugger;
        if (body[questionFields.stopDate] instanceof Date) {
          body[questionFields.stopDate] = formatDate(body[questionFields.stopDate], DATE_TIME_FORMAT);
        }

        if (Array.isArray(body[questionFields.questionFiles])) {
          body[questionFields.questionFiles] = body[questionFields.questionFiles].map((doc: FileBase) => doc.fileId);
        }
        if (Array.isArray(body[questionFields.answerFiles])) {
          body[questionFields.answerFiles] = body[questionFields.answerFiles].map((doc: FileBase) => doc.fileId);
        }
        if (body[questionFields.orderId] === null || body[questionFields.orderId] === "") {
          body[questionFields.orderId] = EMPTY_OBJECT_ID;
        }

        isNewlyCreated(message.id)
          ? yield self.transport.put<any>(apiUrls.orders.orderQuestion.create, body)
          : yield self.transport.post<any>(apiUrls.orders.orderQuestion.update(message.id), body);

        yield self.load();

        self.notify.success(texts.messages.saved);
        return true;
      } catch (e) {
        self.notify.error(e);
        return false;
      }
    }),

    setCompleted: flow(function* (id: string) {
      try {
        const res = yield self.transport.post<any>(apiUrls.orders.orderQuestion.complete(id), {});
        if (res) {
          yield self.load();

          self.notify.success(texts.messages.saved);
        }

        return true;
      } catch (e) {
        self.notify.error(e);
        return false;
      }
    }),

    remove: flow(function* (mails: Identified[]) {
      try {
        const ids = mails.map((m) => m.id);

        yield self.transport.delete<any>(apiUrls.orders.orderQuestion.remove, { data: { ids } });

        self.load(true);

        self.notify.success(texts.messages.removed);
        return true;
      } catch (e) {
        self.notify.error(e);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    setFiltersType(type: string) {
      if (self.filtersType !== type) {
        self.filtersType = type;
        // self.setPage(1);
      }
    },
    setStatuses(status: any) {
      if (status.id) {
        if (self.selectedStatuses.findIndex((item) => item === status.id) === -1) {
          self.selectedStatuses.push(status.id);
        }
      } else {
        self.selectedStatuses.push(status);
      }
    },
    clearStatuses() {
      self.selectedStatuses.clear();
    },
    removeStatuses(item: any) {
      const index = self.selectedStatuses.findIndex((status) => status === item.id);
      if (index > -1) {
        self.selectedStatuses.splice(index, 1);
      }
    },
    setEmployerId(type: string) {
      if (self.filtersEmployer !== type) {
        self.filtersEmployer = type;
        // self.setPage(1);
      }
    },

    setImplementerId(type: string) {
      if (self.filtersImplementer !== type) {
        self.filtersImplementer = type;
        // self.setPage(1);
      }
    },

    setOrderId(orderId?: string, questionId?: string, empId?: string, typeText?: string, isMounted?: boolean) {
      const query = ExternalQuestionNumber();
      if (isMounted) {
        // self.filtersType = "";
        self.filtersOrderId = "";
        self.filtersById = "";
        self.filtersByType = "";
        self.filtersByEmpId = "";
      }
      if (orderId || orderId === "" || orderId === undefined) {
        self.filtersOrderId = orderId === undefined ? "" : orderId;
        self.filtersById = "";
        self.filtersByType = "";
        self.filtersByEmpId = "";
        self.pureQuery = query.toLowerCase();
        self.query = query;
        self.filtersStatus = "";
        // self.filtersType = "";
      }
      if (questionId || questionId === "") {
        self.filtersById = questionId;
      }

      if (empId || empId === "") {
        self.filtersByEmpId = empId;
      }

      if (typeText || typeText === "") {
        self.filtersByType = typeText;
      }

      return self.setPage(1);
    },

    toggleFiltersStatus(status: string) {
      if (!status) {
        self.filtersType = "";
      } else {
        self.filtersType = self.filtersType === status ? "" : status;
      }

      // self.setPage(1);
    },

    onQueryChanged() {
      self.setPage(1);
    },
  }))
  .actions((self) => ({
    moveSelection: flow(function* (from: OrderQuestionSnapshotType, offcet: number) {
      const index = self.questions.findIndex((confirm) => confirm.id === from.id);
      let newIndex = index + offcet;

      // go back
      if (newIndex < 0) {
        if (self.page > 1) {
          self.page = self.page - 1;
          if (yield self.load()) {
            newIndex = self.questions.length + newIndex;
            return self.questions[newIndex];
          }
        }
      } else if (newIndex >= self.questions.length) {
        newIndex = (self.page + 1) * self.pageSize;
        if (newIndex < self.totalCount) {
          self.page = self.page + 1;

          if (yield self.load()) {
            newIndex = 0;
            return self.questions[newIndex];
          }
        }
      } else {
        return self.questions[newIndex];
      }
    }),

    printFile: flow(function* (fileId: string) {
      const confirm = self.questions.find((m) => {
        return !!m.documentsAsMap[fileId];
      });

      if (confirm) {
        const document = confirm.documentsAsMap[fileId];
        yield document.print();
      }
    }),

    printQuestions: flow(function* (selected: string[]) {
      try {
        const file: FileDescription = yield self.transport.post<any>(apiUrls.orders.orderQuestion.print, {
          ...self.queryParams,
          selected,
        });

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        saveAs(blob, file.name);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    downloadFile: flow(function* (fileId: string) {
      const mail = self.questions.find((m) => {
        return !!m.documentsAsMap[fileId];
      });
      if (mail) {
        const document = mail.documentsAsMap[fileId];
        yield document.download();
        const blob: any = yield base64ToBlob(document.content || "", document.mimeType);
        saveAs(blob, document.name);
      }
    }),

    downloadQuestions: flow(function* (confirms: string[], canClientEdit?: boolean) {
      const source = self.questions.filter((m) => confirms.includes(m.id));
      if (source.length) {
        let files: string[] = [];
        if (canClientEdit) {
          files = source.reduce((acc, m) => {
            return [...acc, ...m.answerFiles.map((d) => d.id)];
          }, [] as string[]);
        } else {
          files = source.reduce((acc, m) => {
            return [...acc, ...m.questionFiles.map((d) => d.id).concat(m.answerFiles.map((d) => d.id))];
          }, [] as string[]);
        }

        if (files.length) {
          try {
            const content: DownloadFileResult = yield self.transport.post<any>(apiUrls.application.files.download, {
              ids: files,
            });

            if (content) {
              const blob: any = yield base64ToBlob(content.content, content.mimeType);

              //
              //as pdf const fileURL = URL.createObjectURL(blob);
              //       const printer = printPdf(fileURL, true);
              //       if (printer) {
              //           printer.onclose = () => URL.revokeObjectURL(fileURL);
              //       }
              saveAs(blob, content.name);
              return true;
            }
          } catch (er) {
            self.notify.error(er);
            return false;
          }
        }
      }

      return false;
    }),

    emptyQuestions(): OrderQuestionSnapshotType {
      return {
        ...emptyQuestion(),
        order: self.filtersOrderId
          ? {
              id: self.filtersOrderId,
              inventoryNumber: "",
              name: "",
            }
          : null,
        orderId: self.filtersOrderId ? self.filtersOrderId : "",
      };
    },
  }))
  .named("OrderConfirmStore");

export type OrderQuestionStoreType = typeof OrderQuestionStore.Type;
export type OrderQuestionStoreSnapshotType = typeof OrderQuestionStore.SnapshotType;

const sortStorage = getSortOption(OrderQuestionStore.name);

export const initialState = (searchQuery = ""): OrderQuestionStoreSnapshotType => {
  const options = sortStorage({ column: DEFAULT_COLUMN, asc: DEFAULT_SORTING_ASCENDING_VALUE });

  return {
    questions: [],
    orders: emptyOrders(),
    employee: emptyEmployee(),
    page: 1,
    pageSize: 40,
    totalCount: -1,
    sorter: {
      id: OrderQuestionStore.name,
      tableName: OrderQuestionStore.name,
      column: options.column,
      asc: options.asc,
    },
    renderer: {
      cache: {},
      id: "",
    },
    filtersType: "",
    filtersStatus: "",
    filtersOrderId: "",
    filtersById: "",
    filtersByType: "",
    filtersByEmpId: "",
    filtersEmployer: "",
    filtersImplementer: "",
    selectedStatuses: [],
    statuses: orderStatusDictionary(),
    query: searchQuery,
    pureQuery: searchQuery.toLowerCase(),
  };
};

export const ExternalQuestionNumber = () => {
  const hash = trimStart("#", window.location.hash);
  const params = parse(hash);

  return (params["number"] as string) ?? "";
};
