import { types } from "mobx-state-tree";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "modules/common/constants";
import { BaseEntity } from "modules/common/models/entity";
import { asFileBase } from "modules/common/models/file-metadata";
import { Notificator } from "modules/common/models/notificator";
import { formatDate } from "modules/common/services/formatting/date";
import { nameof } from "modules/common/services/typescript";
import moment from "moment";
import { OrderConfirmFile, OrderConfirmFileType } from "./order-file";
import { StringifiedOrderLink } from "./order-link";
import { DictionaryLink } from "modules/common/models/dictionary-link";

export const Message = types
  .model({
    id: types.string,
    description: types.string,
    date: types.string,
    day: types.string,
    hours: types.string,
    employer: types.maybeNull(DictionaryLink),
  })
  .named("Message");

export const OrderQuestion = types
  .compose(
    BaseEntity,
    Notificator,
    types.model({
      stopDate: types.string,
      day: types.string,
      stopDay: types.string,
      stopDateSortable: types.string,
      stopDays: types.array(types.string),
      oldStopDates: types.array(types.string),
      completed: types.boolean,
      dateSortable: types.string,
      orderId: types.string,
      order: types.maybeNull(StringifiedOrderLink),
      description: types.string,
      number: types.string,
      messages: types.array(Message),
      author: types.maybeNull(DictionaryLink),
      employer: types.maybeNull(DictionaryLink),
      implementer: types.maybeNull(DictionaryLink),
      questionFiles: types.array(OrderConfirmFile),
      answerFiles: types.array(OrderConfirmFile),
      answer: types.string,
      canEditQuestion: types.boolean,
      canRemoveQuestion: types.boolean,
      canAddAnswer: types.boolean,
      canAddImplementer: types.boolean,
      canChangeCompleted: types.boolean,
      orderStatusId: types.string,
    })
  )
  .views((self) => ({
    get dateAsDate() {
      return moment(self.created, DATE_TIME_FORMAT);
    },
    get documentsAsMap() {
      let docs: OrderConfirmFileType[] = [];

      docs = self.questionFiles.concat(self.answerFiles);

      return docs.reduce((result, doc) => {
        result[doc.id] = doc;
        return result;
      }, {} as TStringMap<OrderConfirmFileType>);
    },
  }))
  .named("OrderQuestion");

export const mapQuestion = (question: OrderQuestionSnapshotType, orderGip?: string | null): SaveQuestion => {
  return {
    id: question.id,
    orderId: question.orderId,
    description: question.description,
    completed: question.completed,
    stopDate: question.stopDate,
    employerId: !question.id && orderGip ? orderGip : question.employer ? question.employer.id : "",
    employerName: question.employer ? question.employer.name : "",
    implementerId: question.implementer ? question.implementer.id : "",
    implementerName: question.implementer ? question.implementer.name : "",
    questionFiles: question.questionFiles.map(asFileBase),
    answerFiles: question.answerFiles.map(asFileBase),
    answer: question.answer,
    messages: question.messages,
  };
};

export interface SaveQuestion {
  id: string;
  stopDate: string;
  orderId: string;
  description: string;
  employerId: string;
  employerName: string;
  implementerId: string;
  implementerName: string;
  completed: boolean;
  questionFiles: FileBase[];
  answerFiles: FileBase[];
  answer: string;
  messages: MessageType[];
}

export const tableFields = {
  stopDate: nameof((a: OrderQuestionType) => a.stopDate) as string,
  orderId: nameof((a: OrderQuestionType) => a.orderId) as string,
  description: nameof((a: OrderQuestionType) => a.description) as string,
  completed: nameof((a: OrderQuestionType) => a.completed) as string,
  author: nameof((a: OrderQuestionType) => a.author) as string,
  employer: nameof((a: OrderQuestionType) => a.employer) as string,
  implementer: nameof((a: OrderQuestionType) => a.implementer) as string,
  questionFiles: nameof((a: OrderQuestionType) => a.questionFiles) as string,
  answerFiles: nameof((a: OrderQuestionType) => a.answerFiles) as string,
  messages: nameof((a: OrderQuestionType) => a.messages) as string,
  stopDays: nameof((a: OrderQuestionType) => a.stopDays) as string,
  oldStopDates: nameof((a: OrderQuestionType) => a.oldStopDates) as string,
  answer: nameof((a: OrderQuestionType) => a.answer) as string,
  order: nameof((a: OrderQuestionType) => a.order) as string,
  day: nameof((a: OrderQuestionType) => a.day) as string,
  stopDay: nameof((a: OrderQuestionType) => a.stopDay) as string,
  number: nameof((a: OrderQuestionType) => a.number) as string,
};

export const questionFields = {
  stopDate: nameof((a: SaveQuestion) => a.stopDate) as string,
  orderId: nameof((a: SaveQuestion) => a.orderId) as string,
  description: nameof((a: SaveQuestion) => a.description) as string,
  completed: nameof((a: SaveQuestion) => a.completed) as string,
  employerId: nameof((a: SaveQuestion) => a.employerId) as string,
  implementerId: nameof((a: SaveQuestion) => a.implementerId) as string,
  questionFiles: nameof((a: SaveQuestion) => a.questionFiles) as string,
  answerFiles: nameof((a: SaveQuestion) => a.answerFiles) as string,
  answer: nameof((a: SaveQuestion) => a.answer) as string,
  messages: nameof((a: SaveQuestion) => a.messages) as string,
};

export type OrderQuestionType = typeof OrderQuestion.Type;
export type OrderQuestionSnapshotType = typeof OrderQuestion.SnapshotType;

export type MessageType = typeof Message.Type;
export type MessageSnapshotType = typeof Message.SnapshotType;

export const initialState = (): OrderQuestionSnapshotType => ({
  id: "",
  created: formatDate(new Date(), DATE_FORMAT),
  orderId: "",
  stopDate: "",
  author: null,
  employer: null,
  implementer: null,
  order: null,
  day: "",
  stopDay: "",
  stopDateSortable: "",
  dateSortable: "",
  description: "",
  completed: false,
  questionFiles: [],
  stopDays: [],
  oldStopDates: [],
  answerFiles: [],
  answer: "",
  messages: [],
  canEditQuestion: false,
  canRemoveQuestion: false,
  canAddAnswer: false,
  canAddImplementer: false,
  canChangeCompleted: false,
  number: "",
  orderStatusId: "",
});
