import { Classes } from "@blueprintjs/core";
import { observer } from "mobx-react";
import {
  filterProjectItemPredicate,
  ProjectSelect,
  ProjectSelectedOption,
  renderProjectOption,
} from "modules/common/components/form/ProjectSelect";
import { Search } from "modules/common/components/form/Search";
import { ALL_STATUSES } from "modules/common/constants";
import { DictionaryItemSnapshotType } from "modules/common/models/entity";
import {
  DefaultSelectedOption,
  filterItemPredicate,
  renderSingleOption,
  SimpleSelect,
} from "modules/common/services/form/select";
import { OrderDictionaryItemType, OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { Constants } from "modules/root/models/constants";
import React, { useCallback, useEffect, useState } from "react";
import { OrderQuestionStoreType } from "../models/store";
import styles from "./Filters.module.scss";
import { FiltersContract } from "./types";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";

export const Filters: React.FC<FiltersProps> = (props) => {
  let options: { [k: string]: any } = {};
  const [filterItem, setfilterItem] = useState({ id: "", label: "" });
  const [orderItem, setorderItem] = useState<OrderDictionaryItemType | null>(null);

  const [employerItem, setEmployerItem] = useState<{ id: string; label: string } | null>(null);

  const [implementerItem, setImplementerItem] = useState<{ id: string; label: string } | null>(null);

  const filterStatuses = [ALL_STATUSES, "Исполнено", "Неисполнено", "Просрочено"];
  let i = 0;
  filterStatuses.forEach((label) => {
    let res: DictionaryItemSnapshotType = { id: i.toString(), label };
    options[i] = res;
    i++;
  });
  const item = options[0];
  useEffect(() => {
    setfilterItem(item);
    // let newOrders: any[] = [];
    // console.info(props.orders);
    // if (props.orders) {
    //   console.info([...props.orders.orders]);
    //   const t = toJS(props.orders.orders).map((r) => r);
    //   newOrders = [...toJS(props.orders.orders)];
    //   newOrders.unshift({
    //     id: EMPTY_OBJECT_ID,
    //     inventoryNumber: 0,
    //     name: "Без проекта",
    //     label: "Без проекта",
    //     employer: null,
    //     fullInventoryNumber: "",
    //     orderNumber: "",
    //     color: "",
    //     status: "",
    //     statusColor: "",
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { children, query, orders, setQuery, hideProject, employers } = props;
  const { setOrderId, toggleFiltersStatus, setEmployerId, setImplementerId } = props;

  const selectStatuses = useCallback(
    (selection: string) => {
      let status = selection;
      if (selection === ALL_STATUSES) {
        status = "";
      }

      toggleFiltersStatus(status || "");
    },
    [toggleFiltersStatus]
  );

  const projectSelected = useCallback(
    (project: OrderDictionaryItemType | null) => {
      if (project !== null) {
        setOrderId(project.id);
        setorderItem(project);
      } else {
        setOrderId("");
        setorderItem(null);
      }
    },
    [setOrderId]
  );

  const employerSelected = useCallback(
    (item: { id: string; label: string } | null) => {
      if (item !== null) {
        setEmployerId(item.id);
        setEmployerItem(item);
      } else {
        setEmployerId("");
        setEmployerItem(null);
      }
    },
    [setEmployerId]
  );

  const implementerSelected = useCallback(
    (item: { id: string; label: string } | null) => {
      if (item !== null) {
        setImplementerId(item.id);
        setImplementerItem(item);
      } else {
        setImplementerId("");
        setImplementerItem(null);
      }
    },
    [setImplementerId]
  );

  return (
    <div className={styles.filters}>
      <div style={{ display: "flex" }}>
        <div className={styles.statusFilter} style={{ width: "230px", marginLeft: "0px" }}>
          <StatusFilterHeader
            width={300}
            currentOptions={options}
            value={filterItem.id}
            onChange={async (status: any) => {
              setfilterItem(status);
              selectStatuses(status.label);
            }}
          />
        </div>
        {orders && !hideProject && (
          <div className={styles.orderFilter} style={!hideProject ? { width: "230px" } : {}}>
            <ProjectSelect
              className={`${Classes.FILL} planr-default-input`}
              filterable={true}
              itemPredicate={filterProjectItemPredicate}
              activeItem={orderItem}
              items={orders.orders}
              itemRenderer={renderProjectOption}
              onItemSelect={projectSelected}
              resetOnClose={true}
              inputProps={{
                placeholder: "Проект",
              }}
              popoverProps={{
                boundary: "viewport",
                popoverClassName: "import-spendings-table__select",
              }}
            >
              <ProjectSelectedOption option={orderItem} onClear={() => projectSelected(null)} noData="Объект" />
            </ProjectSelect>
          </div>
        )}
        {employers && (
          <>
            <div className={styles.orderFilter} style={!hideProject ? { width: "230px" } : {}}>
              <SimpleSelect
                className={`${Classes.FILL} planr-default-input`}
                filterable={true}
                itemPredicate={filterItemPredicate}
                activeItem={employerItem}
                items={employers.employee}
                itemRenderer={renderSingleOption}
                onItemSelect={employerSelected}
                resetOnClose={true}
                inputProps={{
                  placeholder: "Проект",
                }}
                popoverProps={{
                  boundary: "viewport",
                  popoverClassName: "import-spendings-table__select",
                }}
              >
                <DefaultSelectedOption
                  option={employerItem}
                  onClear={() => employerSelected(null)}
                  what="Ответственный"
                />
              </SimpleSelect>
            </div>
            <div className={styles.orderFilter} style={!hideProject ? { width: "230px" } : {}}>
              <SimpleSelect
                className={`${Classes.FILL} planr-default-input`}
                filterable={true}
                itemPredicate={filterItemPredicate}
                activeItem={implementerItem}
                items={employers.employee}
                itemRenderer={renderSingleOption}
                onItemSelect={implementerSelected}
                resetOnClose={true}
                inputProps={{
                  placeholder: "Проект",
                }}
                popoverProps={{
                  boundary: "viewport",
                  popoverClassName: "import-spendings-table__select",
                }}
              >
                <DefaultSelectedOption
                  option={implementerItem}
                  onClear={() => implementerSelected(null)}
                  what="Исполнитель"
                />
              </SimpleSelect>
            </div>
          </>
        )}
      </div>
      <div style={{ display: "flex", marginRight: "20px" }}>
        {children}
        <div className={styles.tools}>
          <Search query={query} onSearch={setQuery} />
        </div>
      </div>
    </div>
  );
};

interface FiltersProps extends FiltersContract {
  orders?: OrderDictionaryType;
  employers?: EmployerDictionaryType;
  hideProject?: boolean;
  store: OrderQuestionStoreType;
}

interface StatusFilterHeaderProps {
  value: string;
  onChange: (value: any) => void;
  currentOptions: { [k: string]: any };
  width: number;
  filter?: string;
}

const StatusFilterHeader = observer(
  class extends React.Component<StatusFilterHeaderProps> {
    render() {
      const { width, value, currentOptions } = this.props;

      const option = currentOptions[value];
      return (
        <SimpleSelect
          className={`${Classes.FILL} planr-default-input`}
          filterable={false}
          items={Object.values(currentOptions)}
          itemRenderer={renderSingleOption}
          onItemSelect={this.onItemSelect}
          popoverProps={{
            boundary: "viewport",
            popoverClassName: "import-spendings-table__select",
          }}
        >
          <DefaultSelectedOption
            option={option}
            empty={!!Constants.employerDocumentType.values.length}
            style={{ overflow: "hidden", maxWidth: `${width - 3}px` }}
          />
        </SimpleSelect>
      );
    }

    onItemSelect = (item: SelectItem) => this.props.onChange(item);
  }
);
